var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-markup-table", _vm._b({}, "q-markup-table", _vm.$attrs, false), [
    _c("thead", [
      _c("tr", [_c("th", [_vm._v("Name")]), _c("th", [_vm._v("Created")])]),
    ]),
    _c(
      "tbody",
      [
        _c(
          "tr",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.templates.length,
                expression: "!templates.length",
              },
            ],
          },
          [
            _c(
              "td",
              { attrs: { colspan: "2" } },
              [
                _c("templates-loader", {
                  attrs: { "account-id": _vm.accountId },
                  model: {
                    value: _vm.templates,
                    callback: function ($$v) {
                      _vm.templates = $$v
                    },
                    expression: "templates",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._l(_vm.templates, function (template) {
          return _c("tr", { key: template.id }, [
            _c(
              "td",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: "/templates/" + template.id,
                      "account-id": _vm.accountId,
                    },
                  },
                  [_vm._v("\n        " + _vm._s(template.name) + "\n      ")]
                ),
              ],
              1
            ),
            _c(
              "td",
              [_c("humanize-time", { attrs: { time: template.createdAt } })],
              1
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }