import api from '@/API';
import { Template } from '../types';

export default {
  find(id: string): Promise<Template> {
    return api
      .get(`templates/${id}`)
      .then(response => response.data);
  },
  findAll(accountId: string): Promise<Template[]> {
    return api
      .get(`accounts/${accountId}/templates`)
      .then(response => response.data);
  },
};
