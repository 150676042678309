




























import Vue from 'vue';
import { HumanizeTime } from 'mycorems-app-shared';
import TemplatesLoader from './TemplatesLoader.vue';
import RouterLink from '../routing/RouterLink.vue';
import { Template } from '../types';

interface Data {
  templates: Template[]
}

export default Vue.extend({
  components: {
    TemplatesLoader,
    RouterLink,
    HumanizeTime,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      templates: [],
    };
  },
});
