










import { HasTaskMixin, ResourceLoader } from 'mycorems-app-shared';
import { Template } from '../types';
import repository from './template-repository';

interface Data {
  task: Promise<Account>|undefined,
}

export default HasTaskMixin.extend({
  components: { ResourceLoader },
  model: {
    prop: 'templates',
    event: 'loaded',
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
    templates: Array as () => Template[],
  },
  watch: {
    accountId: {
      handler() {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    load() {
      this.task = repository.findAll(this.accountId);
      this.task.then((templates) => {
        this.$emit('loaded', templates);
      });
    },
  },
});
