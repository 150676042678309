







import Vue from 'vue';
import { Account } from '../types';
import AccountLoader from '../account/AccountLoader.vue';

interface Data {
  account: Account|null,
}

export default Vue.extend({
  components: {
    AccountLoader,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      account: null,
    };
  },
});
