









import Vue from 'vue';
import { openModal } from 'mycorems-app-shared';
import GenerateTemplateModal from './GenerateTemplateModal.vue';

export default Vue.extend({
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  methods: {
    openTemplateModal() {
      openModal(GenerateTemplateModal, {
        parent: this,
        accountId: this.accountId,
      });
    },
  },
});
