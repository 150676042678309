var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t(
        "default",
        [
          _c(
            "resource-loader",
            { attrs: { task: _vm.task, resource: _vm.account } },
            [_vm._v("\n      Account not found.\n    ")]
          ),
        ],
        null,
        { task: _vm.task, isLoading: _vm.isLoading, account: _vm.account }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }