


















import { Modal, ModalMixin, AsyncBtn } from 'mycorems-app-shared';
import templateService from './template-service';
import { Template } from '../types';

interface Data {
  name: string,
  task: Promise<any>|null,
}

export default ModalMixin.extend({
  components: {
    Modal,
    AsyncBtn,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      name: '',
      task: null,
    };
  },
  computed: {
    canGenerate(): boolean {
      return (this.name !== '');
    },
  },
  methods: {
    generate() {
      this.task = templateService.create(this.accountId, this.name);
      this.task.then((template: Template) => {
        this.hide();
        this.$router.push(`/plan/accounts/${this.accountId}/templates/${template.id}`);
      });
    },
  },
});
