var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-btn", {
    attrs: { color: "primary", icon: "fa fa-plus", label: "New Template" },
    on: { click: _vm.openTemplateModal },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }