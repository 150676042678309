












import Vue from 'vue';
import NewTemplateBtn from '../../template/NewTemplateButton.vue';
import TemplatesTable from '../../template/TemplatesTable.vue';

export default Vue.extend({
  components: {
    NewTemplateBtn,
    TemplatesTable,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
});
