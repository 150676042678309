var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    _vm._b(
      {
        attrs: {
          prefix: _vm.prefix,
          to: _vm.to,
          "account-id": _vm.accountId,
          query: _vm.query,
        },
      },
      "router-link",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }