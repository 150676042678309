import Admin from './Admin.vue';
import AgencyAdmin from './agency/AgencyAdmin.vue';
import Templates from './agency/Templates.vue';

export default [
  {
    path: 'accounts/:accountId/admin',
    component: Admin,
    props: true,
    children: [
      {
        path: 'agency',
        component: AgencyAdmin,
        children: [
          {
            path: 'templates',
            component: Templates,
            props: true,
          },
        ],
      }
    ]
  },
];

