import Vue from 'vue';
import { quasarComponents } from 'mycorems-app-shared';
import './styles/quasar.styl';
import iconSet from 'quasar/icon-set/fontawesome-v5.js';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import Quasar, {
  QIcon,
  QBtn,
  QBtnGroup,
  QBtnDropdown,
  QInput,
  QCheckbox,
  QRadio,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QSelect,
  ClosePopup,
  Ripple,
  QCard,
  QCardSection,
  QCardActions,
  QSeparator,
  QMarkupTable,
  QTable,
  QTr,
  QTd,
  QLinearProgress,
  QDialog,
  QDate,
  QPopupProxy,
  QSpace,
  QStepper,
  QStep,
  QTabs,
  QTab,
  QTabPanels,
  QTabPanel,
  QAvatar,
  QChip,
  QBadge,
  QMenu,
  QToggle,
  QSpinner,
  QRouteTab,
  Notify,
  Dialog,
  QToolbar,
  QToolbarTitle,
  QLayout,
  QDrawer,
  QScrollArea,
  QPage,
  QPageContainer,
  QHeader,
  QCarousel,
  QCarouselSlide,
  QCarouselControl,
  QExpansionItem,
  QBtnToggle,
  QForm,
  QImg,
} from 'quasar';

Vue.use(Quasar, {
  config: {},
  components: {
    QIcon,
    QBtn,
    QBtnGroup,
    QBtnDropdown,
    QInput,
    QCheckbox,
    QRadio,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QSelect,
    QCard,
    QCardSection,
    QCardActions,
    QSeparator,
    QMarkupTable,
    QTable,
    QTr,
    QTd,
    QLinearProgress,
    QDialog,
    QDate,
    QPopupProxy,
    QSpace,
    QStepper,
    QStep,
    QTabs,
    QTab,
    QTabPanels,
    QTabPanel,
    QAvatar,
    QChip,
    QBadge,
    QMenu,
    QToggle,
    QSpinner,
    QRouteTab,
    QToolbar,
    QToolbarTitle,
    QLayout,
    QDrawer,
    QScrollArea,
    QPage,
    QPageContainer,
    QHeader,
    QCarousel,
    QCarouselSlide,
    QCarouselControl,
    QExpansionItem,
    QBtnToggle,
    QForm,
    QImg,
    ...quasarComponents,
  },
  directives: {
    'v-close-popup': ClosePopup,
    'v-ripple': Ripple,
  },
  plugins: {
    Notify,
    Dialog,
  },
  iconSet,
});
