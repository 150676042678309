<template>
  <router-link
    :prefix="prefix"
    :to="to"
    :account-id="accountId"
    :query="query"
    v-bind="$attrs"
  >
    <slot></slot>
  </router-link>
</template>

<script>
import config from '../config';

export default {
  components: {
    RouterLink: () => import('mycorems_app/routing/RouterLink'),
  },
  props: {
    to: {
      type: String,
      required: true,
    },
    accountId: String,
    query: Object,
  },
  data() {
    return {
      prefix: config.URL_PREFIX,
    };
  },
};
</script>
