var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "admin-layout",
    {
      attrs: { heading: "Plan Administration", account: _vm.account },
      scopedSlots: _vm._u([
        {
          key: "side",
          fn: function () {
            return [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/admin/agency/templates",
                    "account-id": _vm.account.id,
                  },
                },
                [
                  _c("nav-item", {
                    attrs: { icon: "fas fa-file-lines", label: "Templates" },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }