var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: "Generate Template" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("async-btn", {
                attrs: {
                  task: _vm.task,
                  label: "Generate",
                  color: "primary",
                  disabled: !_vm.canGenerate,
                },
                on: { click: _vm.generate },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.generate($event)
            },
          },
        },
        [
          _c("q-input", {
            attrs: { label: "Name", outlined: "" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }