var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("account-loader", {
        attrs: { "account-id": _vm.accountId },
        model: {
          value: _vm.account,
          callback: function ($$v) {
            _vm.account = $$v
          },
          expression: "account",
        },
      }),
      _vm.account
        ? _c("router-view", { attrs: { account: _vm.account } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }