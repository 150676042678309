import adminRoutes from '../admin/routes';

export default [
  ...adminRoutes,
  {
    path: 'accounts/:accountId',
    component: () => import('./RouterView.vue'),
    children: [
      {
        path: '',
        component: () => import('../Home.vue'),
        props: true,
      },
      {
        path: 'business-discovery',
        component: () => import('../bus-discovery/BusDiscoveryIndex.vue'),
        props: true,
      },
      {
        path: 'templates/:templateId',
        component: () => import('../template/TemplateViewer.vue'),
        props: true,
        meta: {
          title: 'Template',
        },
      },
    ],
  },
];
